import React, { useState,useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    SnackbarContent,
    IconButton,
    Typography,
    Input,
    Box,
    Grid,
    FormHelperText,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    Divider,
    CircularProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import uploadIcon from '../icons/upload.svg';
import infoIcon from '../icons/info.svg';
import closeIcon from '../icons/close.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import './style.css'

const UploadSection = ({ updateTableData, setLoading, onFileProcessStart, onFileProcessComplete, inProgressCount, setFileLimitSnackbarOpen, updateIntensiveComplete }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [journal, setJournal] = React.useState('');
    let userDataJson = JSON.parse(localStorage.getItem("userData"));
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [open, setOpen] = React.useState(false);
    const [fileName, setFileName] = React.useState('');
    const [fileExtension, setFileExtension] = React.useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showFileSizeExceededSnackbar, setShowFileSizeExceededSnackbar] = useState(false);
    const [fileNameExceededSnackbar, setFileNameExceededSnackbar] = useState(false);
    const [showFileTypeSnackbar, setFileTypeSnackbar] = useState(false);
    const [FileNameValidationSnackbar, setFileNameValidationSnackbar] = useState(false);
    const [wordCountExceededSnackbar, setWordCountExceededSnackbar] = useState(false);
    const [spinningIntensiveIcons, setSpinningIntensiveIcons] = useState({});
    // const [langProInprogressSnackbar, setLangProInprogressSnackbar] = useState(false);
    const [minWordSnackbar, setMinWordSnackbar] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const fileInputRef = useRef(null);
    useEffect(() => {
        const savedSpinningIntensiveIcons = JSON.parse(localStorage.getItem("spinningIntensiveIcons"));
        if (savedSpinningIntensiveIcons) {
            setSpinningIntensiveIcons(savedSpinningIntensiveIcons);
        }
    }, []);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleFileClick = () => {
        // Simulate a click on the file input
        fileInputRef.current.click();
    };

    const handleClose = () => {
        setOpen(false);
    };
    // function checkFileSize(input) {
    //     const maxFileSize = 25 * 1024 * 1024; // 25 MB in bytes

    //     if (input.files.length > 0) {
    //         const fileSize = input.files[0].size;
    //         if (fileSize > maxFileSize) {
    //             input.value = '';
    //             return -1;
    //         } else {
    //             return fileSize;
    //         }
    //     } else {
    //         return -1; // Return -1 if no file is selected
    //     }
    // }
    function getToday() {
        try {
            var month = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            var today = new Date();
            var fday = {
                year: '',
                month: '',
                date: '',
                time: '',
                datetime: ''
            };
            //var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            fday.year = '' + yyyy;
            fday.month = month[mm - 1] + '_' + yyyy;
            dd = (dd < 10) ? '0' + dd : dd;
            mm = (mm < 10) ? '0' + mm : mm;
            fday.date = dd + '-' + mm + '-' + yyyy;
            var hours = today.getHours();
            var minutes = today.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            fday.time = hours + ':' + minutes + ' ' + ampm;
            fday.datetime = fday.date + ' ' + fday.time;
            return fday;
        } catch (error) {
            console.debug(error);
        }
    }
    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    function sanitizeFilename(filename) {
        const allowedCharsRegex = /^[a-zA-Z0-9-_ ]+$/;
        filename = filename.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;');
        filename = filename.replace(/[^a-zA-Z0-9-_ ]/g, '');
        const maxLength = 100;
        if (filename.length > maxLength) {
            filename = filename.substring(0, maxLength);
            return "file limit exceed"
        }
        return filename;
    }
    const handleFileChange = (event) => {
        try {
            setIsUploading(true);
            if (event.target.files.length > 0) {
                setIsProcessing(false);
                const maxFileSize = 40 * 1024 * 1024; // 20 MB in bytes
                const file = event.target.files[0];
                const fileNameParts = file.name.split('.');
                var filenameRegex = /^[a-zA-Z0-9-_ ]+$/;
                var matches = fileNameParts[0].match(filenameRegex)
                if (!matches) {
                    setFileNameValidationSnackbar(true);
                    setIsProcessing(true);
                    setIsUploading(false);
                    setUploadedFileName('');
                    event.target.value = ''; // Clear the file input
                    return;
                }
                let fnameVal = sanitizeFilename(fileNameParts[0]);
                if (fnameVal == "file limit exceed") {
                    setFileNameExceededSnackbar(true);
                    setIsProcessing(true);
                    setIsUploading(false);
                    setUploadedFileName('');
                    event.target.value = ''; // Clear the file input
                    return;
                }
                const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
                // Check if the file extension is .docx
                if (fileExtension !== 'docx' && fileExtension !== 'doc') {
                    // if (fileExtension !== 'docx') {
                    // Show an error message or handle invalid file type
                    setFileTypeSnackbar(true); // Show the Snackbar
                    setIsProcessing(true);
                    setIsUploading(false);
                    setUploadedFileName('');
                    event.target.value = ''; // Clear the file input
                    return;
                }
                if (file.size > maxFileSize) {
                    setShowFileSizeExceededSnackbar(true); // Show the Snackbar
                    event.target.value = ''; // Clear the file input
                    setIsUploading(false);
                    setUploadedFileName('');
                    setIsProcessing(true);
                    return;
                }

                // Check word count
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const arrayBuffer = e.target.result;
                    const uint8Array = new Uint8Array(arrayBuffer);
                    const bufferVal = arrayBufferToBase64(uint8Array);
                    // const count = wordCount(text);
                    const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/wordCount', {
                        buffer: bufferVal,
                        fileName: file.name,
                    },);
                    // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/wordCount', {
                    //     buffer: bufferVal,
                    //     fileName: file.name,
                    // });
                    let WordCount = response.data;
                    if (WordCount.wordCount > 25000) {
                        setWordCountExceededSnackbar(true);
                        event.target.value = ''; // Clear the file input
                        setIsProcessing(true);
                        setUploadedFileName('');
                        setIsUploading(false);
                        return;
                    }
                    else if (WordCount < 1) {
                        setMinWordSnackbar(true);
                        event.target.value = ''; // Clear the file input
                        setIsProcessing(true);
                        setUploadedFileName('');
                        setIsUploading(false);
                        return;
                    }
                    // Proceed with processing the file
                    let filename_with_ext = file.name;
                    var lastIndex = filename_with_ext.lastIndexOf('.');
                    let fileName = filename_with_ext.substr(0, lastIndex);
                    setFileName(fileName);
                    let fileExtension = filename_with_ext.substr(lastIndex);
                    setFileExtension(fileExtension);
                    localStorage.setItem("fileExtension", fileExtension);

                    let files = event.target.files;
                    for (let i = 0; i < files.length; i++) {
                        var fileToLoad = event.target.files[i];
                        let files = event.target.files;
                        let file = files[i];
                        var fileReader = new FileReader();
                        fileReader.onload = function (fileLoadedEvent) {
                            let docx = fileLoadedEvent.target.result;
                            const fileDetails = {
                                name: file.name,
                                type: file.type,
                                size: file.size,
                                content: arrayBufferToBase64(docx),
                            };
                            setSelectedFile(fileDetails);
                        };
                        fileReader.readAsArrayBuffer(fileToLoad, "UTF-8");
                        setSnackbarOpen(true);
                        setUploadedFileName(file.name);
                        setIsProcessing(false);
                        setIsUploading(false);
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                setIsProcessing(true);
                setIsUploading(false);
                setUploadedFileName('');
            }
        } catch (error) {
            console.debug(error);
            setIsProcessing(true);
            setIsUploading(false);
            setUploadedFileName('');
        }
    };

    const handleChange = (event) => {
        setJournal(event.target.value);
    };
    const langTechApi = async (cleanedFileName) => {
        try {

            // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/storeManuscriptQueue', {
            //     processID: selectedFile.processID,
            //     FileName: cleanedFileName,
            //     FileExtension: fileExtension,
            //     Journal_Short: journal,
            //     userId: userDataJson.userName,
            //     customer: userDataJson.tenantName.toLowerCase(),
            //     content: selectedFile.content,
            //     FileSize: JSON.stringify(selectedFile.size)
            // }, { withCredentials: true });
            const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/storeManuscriptQueue', {
                processID: selectedFile.processID,
                FileName: cleanedFileName,
                FileExtension: fileExtension,
                Journal_Short: journal,
                userId: userDataJson.userName,
                customer: userDataJson.tenantName.toLowerCase(),
                content: selectedFile.content,
                FileSize: JSON.stringify(selectedFile.size)
            });
            if (response.status === 200) {
                onFileProcessComplete(selectedFile.processID);
            }
            let responseDataRec = response.data
            let innerRes = responseDataRec.result;
            let mainInnerRes = innerRes.outValue
            let apiRespData = {};
            apiRespData.processID = responseDataRec.processID;
            apiRespData.FileName = selectedFile.name;
            apiRespData.quality = mainInnerRes.Manuscript_Score;
            updateTableData(apiRespData)
            setJournal('');
            setSelectedFile(null);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const intensiveApi = async (processID, fileName) => {
        setSpinningIntensiveIcons(prevState => ({ ...prevState, [processID]: true }));
        let cleanedFileName = fileName.replace(/\.docx$/, '');
        let userProfileVal = localStorage.getItem("UserName");
        let tenantName = localStorage.getItem("tenantName");
        // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/inlpforwordqueue', {
        //     processID: processID,
        //     fileName: cleanedFileName,
        //     fileSize: '10mb',
        //     bookCode: 'JOUR',
        //     langDialect: 'en-author',
        //     userName: userProfileVal,
        //     copyEditLevel: 'intensiveEdit',
        //     customer: tenantName||'internal',
        //     fileContent: 'iMLA_INPUT',
        //     time: getToday().datetime
        // }, { withCredentials: true });
        // setLangProInprogressSnackbar(true);
        const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/inlpforwordqueue', {
            processID: processID,
            fileName: cleanedFileName,
            fileSize: '10mb',
            bookCode: 'JOUR',
            langDialect: 'en-author',
            userName: userProfileVal,
            copyEditLevel: 'intensiveEdit',
            customer: tenantName || 'internal',
            fileContent: selectedFile.content,
            time: getToday().datetime
        });
        if (response.status == 200) {
            // Call the callback function in the parent component to update its state
            updateIntensiveComplete(processID, 1);
            setSpinningIntensiveIcons(prevState => ({ ...prevState, [processID]: false }));
        }
        else {
            updateIntensiveComplete(processID, -1);
        }
    }
    const handleProcessClick = async () => {
        try {
            if (inProgressCount > 5) {
                setFileLimitSnackbarOpen(true);
                return;
            }
            onFileProcessStart();
            setIsProcessing(true);
            selectedFile.status = "inprogress";
            let fName = selectedFile.name;
            const cleanedFileName = fName.replace(/\.docx$/, '');
            selectedFile.FileName = cleanedFileName;
            selectedFile.processID = JSON.stringify(generateRandomNumberWithTime())
            updateTableData(selectedFile);
            setJournal('');
            setSelectedFile(null);
            console.log(userDataJson.tenantName.toLowerCase(),"userDataJson.tenantName.toLowerCase()")
            if (userDataJson.tenantName.toLowerCase() == "inlp") {
                langTechApi(cleanedFileName);
                intensiveApi(selectedFile.processID, cleanedFileName)
            }
            else{
                langTechApi(cleanedFileName);
            }

            setUploadedFileName('');
        } catch (error) {
            // Handle error
        } finally {
            setLoading(false);
        }
    };
    const generateRandomNumberWithTime = () => {
        const currentTime = new Date().getTime(); // Get current timestamp
        const randomNumber = Math.floor(Math.random() * 1000); // Generate random number
        const combinedNumber = randomNumber + currentTime; // Combine random number and timestamp
        return parseInt(combinedNumber); // Return the combined number as an integer
    }

    return (
        <Box p={2} className="uploadSectionWrap">
            <Grid container spacing={2}>
                <Grid item xs={3} className="uploadFirstGrid">
                    <span className="fieldLabel">Journal Code</span>
                    <FormControl className='selectFormContr'>
                        <Select
                            value={journal}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className='selectField'>
                            {!journal && (
                                <MenuItem className="firstOpt" value="" disabled>
                                    Select Code
                                </MenuItem>
                            )}
                            <MenuItem value={'JOUN1231691745951691'}>JOUN1231691745951691</MenuItem>
                            <MenuItem value={'JOUN1231691745951692'}>JOUN1231691745951692</MenuItem>
                            <MenuItem value={'JOUN1231691745951693'}>JOUN1231691745951693</MenuItem>
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <span className="fieldLabel" onClick={handleClickOpen}>File Upload <img src={infoIcon} alt="Info Icon" className='infoIcon' /></span>
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"File Upload"}

                            <span className="closeIcon">
                                <span><img src={closeIcon} onClick={handleClose} alt="Close Icon" title="File Validations" /></span>
                            </span>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <DialogContentText>
                                <ul className="infoList">
                                    <li>Manuscript should be in doc / docx file format</li>
                                    <li>Ensure your file size is below 40mb</li>
                                    <li>Word count should be less than 15000</li>
                                </ul>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <FormControl className='uploadFormContr'>
                        <div className="uploadAreaWrap">
                            <div class="uploadIconTxt">
                                <p class="fileUploaded" onClick={handleFileClick}>
                                    <span>Drag and drop doc/docx file</span>
                                    <b className="uploadIconWrap"><img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
                                        Browse</b>
                                </p>
                            </div>
                            <Input
                                type="file"
                                accept=".doc,.docx"
                                ref={fileInputRef}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    opacity: 0,
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                }}
                                multiple
                                onChange={handleFileChange}
                                id="uploadFile"
                            />

                        </div>
                        <FormHelperText><ul>{uploadedFileName && <li>{uploadedFileName} has been uploaded.</li>}</ul></FormHelperText>
                    </FormControl>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={showFileSizeExceededSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setShowFileSizeExceededSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            File size limit exceeded (40 MB). Please select a smaller file.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={fileNameExceededSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setFileNameExceededSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            File name is too long. Please reduce it to within 100 characters.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={FileNameValidationSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setFileNameValidationSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            Avoid space or special characters in file name. Please use standard file naming conventions.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={showFileTypeSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setFileTypeSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            Please Upload Doc/Docx File.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={wordCountExceededSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={() => setWordCountExceededSnackbar(false)}>
                        <MuiAlert
                            variant="filled" severity="warning"
                            className="fileExceedWordSnack"
                        >
                            Word count limit exceeded (25,000 words). Please select a file with fewer words.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={minWordSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={() => setMinWordSnackbar(false)}>
                        <MuiAlert
                            variant="filled" severity="warning"
                            className="minWordCountSnack"
                        >The file seems to be corrupted,Zip or a password Protected.Please upload a another file.
                        </MuiAlert>
                    </Snackbar>
                </Grid>
                <Grid item xs={3} className="processGrid">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleProcessClick}
                        className="processBtn"
                        disabled={isProcessing}
                    >   {isUploading ? (
                        <CircularProgress size={24} color="inherit" /> // Render the spinner if processing
                    ) : (
                        "Process"
                    )}
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={4000}  // Adjust the duration as needed
                onClose={handleSnackbarClose}
                className="uploadSuccessSnack"
            >
                {/* <img src={successCheck} alt="success" /> */}
                <SnackbarContent
                    className="snackContent"
                    message={<span className="snacktextWrap"><CheckCircleIcon className="checkIcon" /><span className="snackText"><p className="hdSnackTxt">Success!</p><p className="subSnackTxt">File uploaded successfully</p></span></span>}
                    action={
                        <IconButton size="small" color="inherit" onClick={handleSnackbarClose} className="snackIconClose">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Snackbar>
        </Box>
    );
};

export default UploadSection;

